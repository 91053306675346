import React from 'react'
import Layout from '../components/Layout'
import CallBackBanner from '../components/CallBackBanner'
import PromotionBanner from '../components/PromotionBanner/PromotionBanner'
import GeneralEnquiryForm from '../components/shared/Form/GeneralEnquiryForm'
import img from '../images/industry-sector/team_industry.svg'
import IndustryTemplateLayout from '../components/IndustrySector/IndustryTemplateLayout'
import MainGrid from '../components/styling/MainGrid'
import Breadcrumb from '../components/shared/Breadcrumb/Breadcrumb'
import IndustryLeftCol from '../components/IndustrySector/IndustryLeftCol'
import IndustryRightCol from '../components/IndustrySector/IndustryRightCol'
import IndustryDescription from '../components/IndustrySector/IndustryDescription'
import logo from '../images/homepage/popin-anim/retailer.svg'
import CaseStudyContent from '../components/CaseStudy/CaseStudyContent'

const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'About us',
    url: '/about-us',
  },
  {
    title: 'Case Studies',
    url: '/case-studies',
  },
  {
    title: 'Weston Homes PLC',
    url: '/weston-homes',
  },
]

const WestonHomes = () => {
  return (
    <Layout
      activeLink="/case-studies"
      title="R&D Weston Homes PLC"
      description="R&D Weston Homes PLC"
    >
      <MainGrid noPaddingMobile noPaddingTablet>
        <Breadcrumb data={breadcrumbData} />
      </MainGrid>
      <IndustryTemplateLayout>
        <IndustryLeftCol
          category="Case Studies"
          title="Weston Homes PLC"
          backLink="/case-studies"
        />
        <IndustryRightCol>
          <IndustryDescription title="Weston Homes PLC" />
          <CaseStudyContent
            heading="Construction Company"
            paragraph='"Following some initial conversations with RDA. We looked into the feasibility of submitting a claim on a no win no fee basis. The RDA team took the time to understand our business and met various key people within each division of our companies to establish what information was needed.'
            // imgDesktop={img}
            // imgMobile={img}
            alt="Aeroplane"
            logo={logo}
            content={[
              <div>
              <p>The input from myself in finance was minimal as the RDA team with their knowledge and experience took care of the rest.</p>
              <p>Because of the group structure and number of years covered there were multiple submissions, and we were always kept in the loop on these submissions, likely timescales and probability of success.</p>
                <p>
                  I am pleased to say that we had 100% of our claims settled from HMRC. In conclusion I would have no hesitation in recommending RDA."
                </p>
              </div>,
            ]}
          />
        </IndustryRightCol>
      </IndustryTemplateLayout>
      <PromotionBanner />
      <GeneralEnquiryForm />
      <CallBackBanner />
    </Layout>
  )
}

export default WestonHomes
